/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-west-1",
    "aws_appsync_graphqlEndpoint": "https://i6vmmtg5tvdzplpsrnfu3u4a24.appsync-api.us-west-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-west-1",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-2so4oikmfffl3jgywc7snzcuoy"
};


export default awsmobile;
