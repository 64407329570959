import React from 'react';
import { createTheme, ThemeProvider } from "@aws-amplify/ui-react";
import { Amplify } from 'aws-amplify';

import PrereleaseHome from './components/PrereleaseHome/PrereleaseHome';
import { studioTheme } from "./ui-components";
import awsconfig from './aws-exports';

import "@aws-amplify/ui-react/styles.css";
import './App.css';


const updatedTheme = createTheme({
  // Extend the theme to update the button color
  name: "hhc-theme",
  tokens: {
    components: {
      checkbox: {
        icon: {
          backgroundColor: {
          value: "#F68A1E"
        },
        }
        
      },
      button: {
        primary: {
          backgroundColor: {
            value: "#F68A1E"
          },
          _active: {
            backgroundColor: {
              value: "#d77009"
            }
          },
          _hover: {
            backgroundColor: {
              value: "#d77009"
            }
          }
        },
        default: {
          _hover: {
            backgroundColor: {
              value: "#eeeeee"
            },
            borderColor: {
              value: "#cccccc"
            }
          },
          _active: {
            backgroundColor: {
              value: "#eeeeee"
            },
            borderColor: {
              value: "#cccccc"
            }
          }
        },
      },
    },
  },
}, studioTheme);

Amplify.configure(awsconfig);

function App() {
  return (
    <div className="App">
      <ThemeProvider theme={updatedTheme}>
        <PrereleaseHome></PrereleaseHome>
      </ThemeProvider>
    </div>
  );
}

export default App;
