import React, { FC, ReactElement, useState } from "react";
import { Button } from "@aws-amplify/ui-react";

import { PrereleaseEmailSignupCreateForm } from "../../ui-components";

import './PrereleaseHome.css';
import hhcAvatarPNG from '../../images/hhc_avatar.png';

const PrereleaseHome: FC<unknown> = (): ReactElement => {
    const [showMessage, setShowMessage] = useState<string>('');
    return <>
        <div className="PrereleaseHome__outer">
            <img alt="HHC logo" src={hhcAvatarPNG} width="160" height="160" /><span className="PrereleaseHome__inner">2023</span>
        </div>

        <h3 style={{textAlign: 'center'}}>Happy 50th anniversary to Hip-Hop </h3>
        <p>Hip-Hop &ndash; Our incredibly powerful and influential cultural movement that has had a profound impact on politics, music, fashion, dance, and art around the world. As hip-hop approaches its 50th anniversary in 2023, it's important to reflect on the incredible journey it has taken and the lasting legacy it has created.
        </p>
        <div className="PrereleaseHome__form">
            <PrereleaseEmailSignupCreateForm
                onError={() => setShowMessage('There was a problem submitting your email. Try again.')}
                onSuccess={() => setShowMessage('We received your email address. Keep a look out for news when we have it!')}
            />
            {showMessage && (
                <div className="PrereleaseHome__form-message">
                    {showMessage}
                    <Button onClick={() => setShowMessage('')}>⨉</Button>
                </div>
            )}
        </div>

        <footer className="PrereleaseHome__legal">&copy; 2023 Hip-Hop.com</footer>
    </>;
}
export default PrereleaseHome;
